<template>
  <!-- modal eliminar -->
  <vs-prompt
    :active="show"
    title="Productos"
    class="modal-products"
    buttons-hidden
  >
    <div class="mb-5 flex justify-between text-right">
      <h3>{{ $t("Productos") }}</h3>
      <feather-icon icon="XIcon" @click="closeModal" class="cursor-pointer" />
    </div>
    <div class="col-span-2 grid grid-cols-4 gap-4 items-end">
      <div class="col-span-2">
        <label for="promotion-products">{{ $t("Agregar un producto") }}:</label>
        <div class="flex gap-3">
          <vs-input v-model="searchSKU" class="w-full" />
        </div>
      </div>
      <div
        v-if="
          (rule && rule.length > 0 && rule[0].activate === 'quantity') ||
          (promotion && promotion.label === 'Combo')
        "
      >
        <label for="promotion-products">{{ $t("Cantidad") }}:</label>
        <div class="flex gap-3">
          <vs-input
            type="number"
            v-model.number="quantity"
            class="w-full"
            min="0"
          />
        </div>
      </div>
      <div>
        <vs-button @click.native="getProductsBySKU" class="w-full">{{
          $t("Agregar")
        }}</vs-button>
      </div>
    </div>
    <div class="mt-4">
      <vs-table
        class="promotions-products-table"
        :data="productsList"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Sku") }}</vs-th>
          <vs-th>{{ $t("Cantidad") }}</vs-th>
          <vs-th>{{ $t("Acciones") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- Nombre -->
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <!-- Sku -->
            <vs-td :data="data[indextr].sku">
              {{ data[indextr].sku }}
            </vs-td>

            <!-- Cantidad -->
            <vs-td :data="data[indextr].trigger_qunt">
              {{ data[indextr].trigger_qunt }}
            </vs-td>

            <!-- eliminar -->
            <vs-td :data="data[indextr]" class="flex items-center gap-5">
              <vs-button
                color="transparent"
                size="small"
                class="w-5"
                @click.native="deleteProduct(data[indextr])"
              >
                <img
                  :src="require(`@/assets/icons/DeleteBlueIcon.svg`)"
                  width="13px"
                />
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="mt-5 col-span-2 flex justify-end">
      <vs-button @click.native="closeModal">{{ $t("Guardar") }}</vs-button>
    </div>
  </vs-prompt>
</template>

<script>
import http from "@/http/promotions-http-client.js";
import VSelect from "vue-select";
export default {
  name: "ModalProducts",
  components: {
    VSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: null,
    },
    promotion: {
      type: Object,
      default: null,
    },
    rule: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      searchSKU: "",
      quantity: 1,
      productsList: [],
    };
  },
  watch: {
    rule: {
      handler() {
        this.productsList = [];
      },
      deep: true,
    },
    products(newValue) {
      if (
        JSON.stringify(newValue) ===
        JSON.stringify(JSON.stringify(this.productsList))
      )
        return;
      this.productsList = newValue;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addProduct(product) {
      if (this.productsList.find((item) => item.sku === product.sku)) return;
      this.productsList.push(product);
      this.$emit("change", this.productsList);
    },
    deleteProduct(product) {
      this.productsList = this.productsList.filter(
        (item) => item.sku !== product.sku
      );
      this.$emit("change", this.productsList);
    },
    async getProductsBySKU() {
      if (!this.searchSKU) return;

      const response = await http.post("api/ProductsSakura", {
        sku: `${this.searchSKU}`,
      });
      if (!response || response.status !== 200) return;
      const product = {
        ...response.data,
        trigger_qunt: this.quantity,
      };
      this.addProduct(product);
      this.searchSKU = "";
      this.quantity = 1;
    },
  },
};
</script>

<style>
.modal-products .vs-dialog {
  max-width: 750px;
}
.modal-products .vs-dialog-header {
  display: none;
}
</style>
