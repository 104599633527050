export default [
  {
    id: "01:00",
    label: "01:00",
  },
  {
    id: "02:00",
    label: "02:00",
  },
  {
    id: "03:00",
    label: "03:00",
  },
  {
    id: "04:00",
    label: "04:00",
  },
  {
    id: "05:00",
    label: "05:00",
  },
  {
    id: "06:00",
    label: "06:00",
  },
  {
    id: "07:00",
    label: "07:00",
  },
  {
    id: "08:00",
    label: "08:00",
  },
  {
    id: "09:00",
    label: "09:00",
  },
  {
    id: "10:00",
    label: "10:00",
  },
  {
    id: "11:00",
    label: "11:00",
  },
  {
    id: "12:00",
    label: "12:00",
  },
  {
    id: "13:00",
    label: "13:00",
  },
  {
    id: "14:00",
    label: "14:00",
  },
  {
    id: "15:00",
    label: "15:00",
  },
  {
    id: "16:00",
    label: "16:00",
  },
  {
    id: "17:00",
    label: "17:00",
  },
  {
    id: "18:00",
    label: "18:00",
  },
  {
    id: "19:00",
    label: "19:00",
  },
  {
    id: "20:00",
    label: "20:00",
  },
  {
    id: "21:00",
    label: "21:00",
  },
  {
    id: "22:00",
    label: "22:00",
  },
  {
    id: "23:00",
    label: "23:00",
  },
];
