<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Nueva promoción") }}</h3>
      <div class="grid grid-cols-2 gap-5">
        <div class="w-full">
          <label for="promotion-name"
            >{{ $t("Nombre de la promoción") }}:</label
          >
          <vs-input
            v-model="form.promotion_name"
            id="promotion-name"
            class="w-full"
          />
          <p
            v-if="errors.promotion_name"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.promotion_name }}
          </p>
        </div>
        <div>
          <label for="promotion-type">{{ $t("Tipo de promoción") }}:</label>
          <vSelect
            id="promotion-type"
            :options="promotionTypes"
            v-model="form.type_promotion_id"
            class="w-full"
          >
          </vSelect>
          <p
            v-if="errors.type_promotion_id"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.type_promotion_id }}
          </p>
        </div>

        <div>
          <label for="promotion-rules"
            >{{ $t("Seleccione las reglas") }}:</label
          >
          <vSelect
            id="promotion-rules"
            :options="promotionRules"
            :reduce="(rule) => [rule]"
            v-model="form.promotion_rules"
            class="w-full"
            :disabled="promotionRules.length === 0"
          >
          </vSelect>
          <p
            v-if="errors.promotion_rules"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.promotion_rules }}
          </p>
        </div>
        <div class="col-span-full">
          <label for="promotion-description">{{ $t("Descripción") }}:</label>
          <vs-textarea
            v-model="form.description_promotion"
            class="w-full mb-0"
            id="promotion-description"
          />
          <p
            v-if="errors.description_promotion"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.description_promotion }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de inicio") }}:</label>
          <vs-input
            type="date"
            v-model="form.date_init"
            id="promotion-name"
            class="w-full"
            :min="dateToString(new Date())"
          />
          <p
            v-if="errors.date_init"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.date_init }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de inicio") }}:</label>
          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="form.init_hour"
            class="w-full"
          >
          </vSelect>
          <p
            v-if="errors.init_hour"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.init_hour }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Fecha de fin") }}:</label>
          <vs-input
            type="date"
            v-model="form.date_end"
            id="promotion-name"
            class="w-full"
            :min="minFinalDate"
            :danger="errors.end_hour.length === ''"
            :danger-text="errors.end_hour"
          />
          <p
            v-if="errors.date_end"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.date_end }}
          </p>
        </div>
        <div class="w-full">
          <label for="promotion-name">{{ $t("Hora de fin") }}:</label>

          <vSelect
            id="promotion-rules"
            :options="optionHours"
            :reduce="(hour) => hour.id"
            v-model="form.end_hour"
            class="w-full"
          />
          <p
            v-if="errors.end_hour"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.end_hour }}
          </p>
        </div>

        <div class="col-span-2">
          <label for="promotion-products " class="text-lg text-info">{{
            $t("Productos")
          }}</label>
          <div class="mt-2 flex justify-between items-center">
            <p>
              {{ $t("Productos uno a uno en la promoción") }}:
              {{ form.products.length }}

              <br />
              <span
                v-if="errors.products"
                class="text-sm font-light span-text-validation-danger"
                >{{ errors.products }}</span
              >
              <span
                >{{ $t("Archivos de productos en la promoción") }}:
                {{ form.file_products ? 1 : 0 }}
              </span>
            </p>

            <div class="flex gap-2">
              <vs-button @click.native="showModalProducts = true">
                {{ $t("Uno a uno") }}</vs-button
              >
              <vs-button @click.native="showModalProductsMassive = true">{{
                $t("Masivo")
              }}</vs-button>
            </div>
          </div>
          <ModalProducts
            :show="showModalProducts"
            :promotion="form.type_promotion_id"
            :rule="form.promotion_rules"
            @close="showModalProducts = false"
            @change="(products) => (form.products = products)"
          />
          <ModalProductsMassive
            :show="showModalProductsMassive"
            @close="showModalProductsMassive = false"
            @save="(file) => (form.file_products = file)"
          />
        </div>

        <div class="col-span-2">
          <label for="promotion-clients" class="text-lg text-info">{{
            $t("Clientes")
          }}</label>
          <div class="mt-2 flex justify-between items-center">
            <p class="font-light">
              {{ $t("Clientes uno a uno en la promoción") }}:
              {{ form.customers.length }}
              <br />
              <span
                v-if="errors.customers"
                class="text-sm font-light span-text-validation-danger"
                >{{ errors.customers }}</span
              >
              <span
                >{{ $t("Archivos de clientes en la promoción") }}:
                {{ form.file_users ? 1 : 0 }}
              </span>
            </p>

            <div class="flex gap-2">
              <vs-button @click.native="showModalClients = true">{{
                $t("Uno a uno")
              }}</vs-button>
              <vs-button @click.native="showModalClientsMassive = true">{{
                $t("Masivo")
              }}</vs-button>
            </div>
          </div>
          <ModalClients
            :show="showModalClients"
            @close="showModalClients = false"
            @change="(clients) => (form.customers = clients)"
          />
          <ModalClientsMassive
            :show="showModalClientsMassive"
            @close="showModalClientsMassive = false"
            @save="(file) => (form.file_users = file)"
          />
        </div>
        <div class="col-span-2">
          <label for="promotion-clients" class="text-lg text-info">{{
            $t("Banner")
          }}</label>
          <BannerUploader @set-banner="setBanner" />
        </div>
      </div>

      <div
        class="mt-8 p-4 text-yellow-500 bg-yellow-100 border-yellow-400 border border-solid rounded-lg"
      >
        <b class="font-semibold">¡{{ $t("Advertencia") }}!</b>
        <ul class="list-disc">
          <li v-if="form.customers.length === 0">
            -
            {{
              $t(
                "Tenga en cuenta que al no seleccionar CLIENTES para esta promoción, la promoción estará activa para todos los usuarios"
              )
            }}.
          </li>
          <li>
            -
            {{
              $t(
                "Tenga en cuenta que al crear la promoción su estado es desactivada, para que la promoción se aplique a los usuarios debe ser activada"
              )
            }}.
          </li>
          <li v-if="form.products.length === 0">
            -
            {{
              $t(
                "Tenga en cuenta que al no seleccionar PRODUCTOS para esta promoción, el descuento se aplicará a todos los productos"
              )
            }}.
          </li>
        </ul>
      </div>

      <div class="mt-10 flex justify-end gap-5">
        <vs-button
          :color="'#FFF'"
          :text-color="'#666'"
          class="rounded-full"
          @click.native="$router.go(-1)"
        >
          {{ $t("Cancelar") }}
        </vs-button>

        <vs-button
          class="rounded-full flex items-center"
          @click.native="addPromotion"
          :disabled="loading"
        >
          <div v-if="loading" class="h-full grid place-items-center gap-2">
            <feather-icon
              :icon="'LoaderIcon'"
              class="text-white w-5 h-2 m-0 p-0 animate-spin"
            />
          </div>
          <p v-else>{{ $t("Agregar") }}</p>
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import http from "@/http/promotions-http-client.js";
import "vue-select/dist/vue-select.css";
import ModalProducts from "./ModalProducts.vue";
import ModalClients from "./ModalClients.vue";
import ModalClientsMassive from "./ModalClientsMassive.vue";
import ModalProductsMassive from "./ModalProductsMassive.vue";
import BannerUploader from "./BannerUploader.vue";
import hours from "@/assets/utils/hours";
export default {
  name: "AddPromotionPage",
  components: {
    ModalProducts,
    ModalClients,
    ModalClientsMassive,
    ModalProductsMassive,
    BannerUploader,
  },
  data() {
    return {
      form: {
        promotion_target: "Para Clientes",
        type_promotion_id: null,
        promotion_name: "",
        description_promotion: "",
        promotion_rules: null,
        products: [],
        customers: [],
        date_init: "",
        date_end: "",
        init_hour: "",
        end_hour: "",
        imageURL: "",
      },
      errors: {
        promotion_name: "",
        type_promotion_id: "",
        description_promotion: "",
        promotion_rules: "",
        customers: "",
        products: "",
        date_init: "",
        date_end: "",
        end_hour: "",
        init_hour: "",
      },
      promotionRules: [],
      promotionTypes: [],
      loading: false,
      showModalProducts: false,
      showModalProductsMassive: false,
      showModalClients: false,
      showModalClientsMassive: false,
      optionHours: hours,
    };
  },
  async created() {
    this.getOptionsTypes();
    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Promociones"),
      subtitle: this.$t("Agrega una promoción"),
    });
  },
  computed: {
    minFinalDate() {
      return this.form.date_init
        ? this.form.date_init
        : this.dateToString(new Date());
    },
  },
  watch: {
    "form.type_promotion_id"(value) {
      this.promotionRules = [];
      this.form.promotion_rules = null;
      this.getOptionsRules(value);
    },
    "form.promotion_rules": {
      handler() {
        this.form.products = [];
      },
      deep: true,
    },
  },
  methods: {
    async addPromotion() {
      if (this.isInvalidForm()) return;
      const { form } = this;
      const data = {
        ...form,
        type_promotion_id:
          this.promotionTypes.find(
            (item) => item.id === form.type_promotion_id.id
          ).id || "",
        products: form.products,
        customers: form.customers.map((item) => item.identification),
        promotion_rules: form.promotion_rules.map((item) => item.id),
      };
      this.loading = true;
      const response = await http
        .post("api/CustonPromotions", data)
        .catch((this.loading = false));
      this.loading = false;
      if (!response || response.status !== 201) return;
      this.$router.go(-1);
    },
    setBanner(bannerUrl) {
      this.form.imageURL = bannerUrl;
    },
    isInvalidForm() {
      this.cleanErrors();
      let invalid = false;
      for (const input in this.errors) {
        if (this.form[input] === "" || this.form[input] === null) {
          this.errors[input] = this.$t("Campo requerido");
          invalid = true;
        }
        if (
          input === "end_hour" &&
          this.form.date_init === this.form.date_end &&
          this.form.init_hour >= this.form.end_hour
        ) {
          this.errors[input] = this.$t(
            "La hora de inicio debe ser mayor a la hora final"
          );
          invalid = true;
        }
      }
      return invalid;
    },
    cleanErrors() {
      for (const error in this.errors) {
        this.errors[error] = "";
      }
    },
    async getOptionsTypes() {
      const response = await http.get("api/TypesPromotionsSh");
      if (response.status !== 200) return;
      this.promotionTypes =
        response.data.map((option) => ({
          id: option.key,
          label: option.name,
        })) || [];
    },
    async getOptionsRules(promotion) {
      const query = `?type=${promotion.id}`;
      const response = await http.get(
        `api/RulesPromotionsSh${promotion ? query : ""}`
      );
      if (response.status !== 200) return;
      this.promotionRules =
        response.data.map((option) => ({
          id: option.key,
          label: option.name,
          activate: option.activate,
        })) || [];
    },
    dateToString(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }

      if (month < 10) {
        month = `0${month}`;
      }
      const dateString = year + "-" + month + "-" + day;

      return dateString;
    },
  },
};
</script>
