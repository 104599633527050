<template>
  <div>
    <input
      type="file"
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      ref="file"
      accept="image/png, image/jpeg"
      @change="onChange()"
    />

    <div
      v-if="!image.file"
      class="border-2 border-dashed text-center pt-5 pb-8 mt-4 cursor-pointer"
      @click="clickFileButton()"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <img
        :src="require(`@/assets/icons/UploadFileIcon.svg`)"
        width="32px"
        class="inline-block align-middle"
      /><br /><br />
      <span class="text-gray">Suelta tu imagen aquí, ó</span>&nbsp;
      <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small"
        >Busca Archivo</vs-button
      >
    </div>

    <div v-if="!image.file" class="vx-row mt-2">
      <div class="vx-col w-1/2">
        <span class="text-gray">Formatos Soportados: JPG, PNG</span>
      </div>

      <div class="vx-col w-1/2">
        <span class="text-gray float-right">Tamaño Máximo: 5Mb</span>
      </div>
    </div>

    <vx-card v-else class="mt-4">
      <div class="vx-row">
        <div class="vx-col w-auto">
          <img
            :src="require(`@/assets/icons/JpgFileIcon.svg`)"
            width="60px"
            class="inline-block align-middle"
          /><br /><br />
        </div>

        <div class="vx-col w-11/12">
          <feather-icon
            icon="XIcon"
            class="float-right cursor-pointer"
            @click="removeImage"
          />
          <h4>{{ image.file.name }}</h4>
          <span>Subido {{ image.progress }}%</span>
          <vs-progress
            :height="12"
            :percent="image.progress"
            :color="image.error ? 'danger' : 'success'"
          ></vs-progress>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: {
        progress: 0,
        error: "",
        file: null,
      },
      urlImage: "",
      validFormats: ["jpg", "jpeg", "png"],
    };
  },
  methods: {
    onChange() {
      const filename = this.$refs.file.files[0].name;
      const filesize = this.$refs.file.files[0].size;
      const filesizeMb = Math.floor(filesize / 1000000);
      const ext = filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();

      const isValidFormat = this.validFormats.filter(
        (format) => format === ext
      );

      if (filesizeMb > 5) {
        this.$vs.notify({
          title: `La imagen tiene un tamaño de ${filesizeMb} Mb`,
          text: `El límite de tamaño es de 2Mb`,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          time: 7000,
        });
        return;
      }

      if (isValidFormat.length === 0) {
        this.$vs.notify({
          title: "Formato Incorrecto",
          text: "Cargue archivos .jpg ó .png solamente",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return;
      }

      this.image.file = this.$refs.file.files[0];
      this.saveImage();
    },
    dragover(ev) {
      ev.preventDefault();

      if (!ev.currentTarget.classList.contains("bg-primary")) {
        ev.currentTarget.classList.remove("bg-gray-100");
        ev.currentTarget.classList.add("bg-primary");
      }
    },
    dragleave(ev) {
      ev.currentTarget.classList.remove("bg-primary");
    },
    drop(ev) {
      ev.preventDefault();

      this.$refs.file.files = ev.dataTransfer.files;
      this.onChange();
      ev.currentTarget.classList.remove("bg-primary");
    },
    clickFileButton() {
      this.$refs.file.click();
    },
    removeImage() {
      this.image.file = null;
      this.urlImage = "";
      this.$emit("set-banner", this.urlImage);
    },
    async saveImage() {
      this.$vs.loading();
      const res = await this.$store
        .dispatch("firebase/uploadImage", this.image)
        .catch(() => {
          this.$vs.loading.close();
          this.setToastMessage(
            "Ocurrió un error al cargar la imagen. Intente unos minutos más tarde"
          );
        });
      this.$vs.loading.close();

      if (!res) return;
      this.urlImage = res;
      this.$emit("set-banner", this.urlImage);
    },
  },
};
</script>

<style></style>
