<template>
  <vs-prompt
    :active="show"
    title="Productos"
    class="modal-massive"
    buttons-hidden
  >
    <div class="mb-8 flex justify-between text-right">
      <h3>{{ $t("Carga masiva de clientes") }}</h3>
      <feather-icon icon="XIcon" @click="closeModal" class="cursor-pointer" />
    </div>
    <div class="flex items-center justify-between">
      <div class="">
        <b class="font-bold text-lg text-black"
          >{{ $t("Nombre del archivo") }} (xlsx):</b
        >
        <br />
        <p class="text-grey-300">
          {{ file ? file.name : $t("Seleccione un archivo") }}
        </p>
      </div>

      <label
        v-if="!file"
        for="fileuploader"
        class="relative inline w-content vs-component vs-button vs-button-primary vs-button-filled"
        :class="loading ? 'pointer-events-none opacity-30' : ''"
      >
        <input
          id="fileuploader"
          type="file"
          class="opacity-0 absolute w-px h-px"
          ref="file"
          accept=".xlsx, .csv"
          @change="(event) => onChange(event)"
        />
        {{ $t("Cargar archivo") }}
      </label>
      <vs-button
        v-else
        class="flex-none"
        @click.native="file = null"
        :class="loading ? 'pointer-events-none opacity-30' : ''"
      >
        {{ $t("Eliminar archivo") }}
      </vs-button>
    </div>
    <div class="mt-8 col-span-2 flex justify-end gap-2">
      <vs-button
        @click.native="downloadFileFormat"
        type="border"
        :class="loading ? 'pointer-events-none opacity-30' : ''"
        >{{ $t("Descargar formato de Carga Masiva") }}</vs-button
      >
      <vs-button
        @click.native="saveFile"
        :class="loading ? 'pointer-events-none opacity-30' : ''"
        >{{ $t("Guardar") }}</vs-button
      >
    </div>
  </vs-prompt>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "ModalClientsMassive",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: null,
    },
    promotion: {
      type: Object,
      default: null,
    },
    rule: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveFile() {
      this.uploadingFile();
    },
    async uploadingFile() {
      const tempFile = this.file;

      if (!tempFile) {
        this.closeModal();
        this.$emit("save", null);
        return;
      }

      const filename = tempFile.name;
      const ext = filename.substr(filename.lastIndexOf(".") + 1);

      const route = `/SAKURA/files/clients_masive/customers.${ext}`;
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(route);
      this.loading = true;
      let response = await fileRef.put(tempFile);
      this.loading = false;
      if (!response) return;
      this.$emit("save", "customers.xlsx");
      this.closeModal();
    },
    onChange(event) {
      const filename = event.target.files[0].name;
      const ext = filename.substr(filename.lastIndexOf(".") + 1);

      if (ext !== "xlsx") {
        return this.$vs.notify({
          title: "Formato Incorrecto",
          text: "Cargue archivos .xlsx ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
      }

      const tempFile = event.target.files[0] || null;
      if (!tempFile) return;
      this.file = tempFile;
    },
    downloadFileFormat() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "getFileUrl",
          "/SAKURA/files/clients_masive/template_customers.xlsx"
        )
        .then((url) => {
          this.$vs.loading.close();
          if (url) {
            const link = document.createElement("a");

            link.setAttribute("download", "true");
            link.href = url;
            link.click();
            link.remove();
          }
        });
    },
  },
};
</script>

<style>
.modal-massive .vs-dialog {
  max-width: 500px;
}
.modal-massive .vs-dialog-header {
  display: none;
}
</style>
