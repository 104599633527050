<template>
  <vs-prompt :active="show" class="modal-clients" buttons-hidden>
    <div class="mb-5 flex justify-between text-right">
      <h3>{{ $t("Clientes") }}</h3>
      <feather-icon icon="XIcon" @click="closeModal" class="cursor-pointer" />
    </div>
    <div class="col-span-2">
      <label for="promotion-clients"
        >{{ $t("Introduce un número de indentificación") }}:</label
      >
      <div class="flex gap-3">
        <vSelect
          id="promotion-clients"
          :options="optionsClients"
          v-model="client"
          class="w-full"
          @search="getClientsByIdentification"
          @option:selected="addClient"
        >
        </vSelect>
      </div>
    </div>
    <div class="mt-4">
      <vs-table
        class="promotions-clients-table"
        :data="clientsList"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Identificación") }}</vs-th>
          <vs-th>{{ $t("Acciones") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- Nombre -->
            <vs-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>

            <!-- Identification -->
            <vs-td :data="data[indextr].identification">
              {{ data[indextr].identification }}
            </vs-td>

            <!-- eliminar -->
            <vs-td :data="data[indextr]" class="flex items-center gap-5">
              <vs-button
                color="transparent"
                size="small"
                class="w-5"
                @click.native="deleteClient(data[indextr])"
              >
                <img
                  :src="require(`@/assets/icons/DeleteBlueIcon.svg`)"
                  width="13px"
                />
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="mt-5 col-span-2 flex justify-end">
        <vs-button @click.native="closeModal">{{ $t("Guardar") }}</vs-button>
      </div>
    </div>
  </vs-prompt>
</template>

<script>
import http from "@/http/promotions-http-client.js";
import VSelect from "vue-select";
export default {
  name: "ModalClients",
  components: {
    VSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    clients: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      client: null,
      clientsList: [],
      optionsClients: [],
      seatching: false,
    };
  },
  watch: {
    clients(newValue) {
      if (
        JSON.stringify(newValue) ===
        JSON.stringify(JSON.stringify(this.clientsList))
      )
        return;
      this.clientsList = newValue;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addClient(client) {
      this.clientsList.push(client);
      this.optionsClients = [];
      this.client = null;
      this.$emit("change", this.clientsList);
    },
    deleteClient(client) {
      this.clientsList = this.clientsList.filter(
        (item) => item.id !== client.id
      );
      this.$emit("change", this.clientsList);
    },
    async getClientsByIdentification(identification) {
      if (!identification) return;
      if (identification.length < 3) return;
      if (this.searching) return;
      this.searching = true;
      const response = await http
        .get(`api/clientSearchSakura/${identification}`)
        .catch();
      this.searching = false;
      if (response.status !== 200) return;
      this.optionsClients = response.data.map((item) => ({
        ...item,
        label: item.identification,
      }));
    },
  },
};
</script>

<style>
.modal-clients .vs-dialog {
  max-width: 750px;
}
.modal-clients .vs-dialog-header {
  display: none;
}
</style>
